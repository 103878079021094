<template>
  <v-dialog v-model="show" persistent :retain-focus="false" max-width="1140">
    <v-card>
      <v-card-title class="justify-center">
        <span class="text-h5"><strong> Edit Donor Information</strong></span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="createdDate"
                hide-details
                dense
                label="Date: YYYY-MM-DD"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                v-model="item.donor_name" 
                label="Donor's Name*" 
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0">Parent/Guardian*:</p>
              <v-radio-group row class="mt-0" v-model="item.s_w_d_of">
                <v-radio name="Parents_name" label="S" value="Son"></v-radio>
                <v-radio name="Parents_name" label="W" value="Wife"></v-radio>
                <v-radio name="Parents_name" label="D" value="Daughter"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="item.donor_parent_guardian_name"
                label="Parent/Guardian Name*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                v-model="item.age" 
                hide-details 
                label="Donor's Age*" 
                required
                 @paste.prevent
                @keypress="onlyAge"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <p class="mb-0">Sex*:</p>
              <v-radio-group row class="mt-0" v-model="item.sex">
                <v-radio name="sex" label="Male" value="Male"></v-radio>
                <v-radio name="sex" label="Female" value="Female"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-textarea
                v-model="item.postal_address"
                hide-details
                label="Postal Address*"
                required
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                v-model="item.phone_no" 
                label="Phone No*" 
                required
                counter="10"
                 @paste.prevent
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                hide-details
                v-model="item.donor_relative_name"
                label="Received from (Name of nearest relation)*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0">Relative relation with Donor*:</p>
              <v-radio-group row class="mt-0" v-model="item.relation_with_donor">
                <v-radio name="donor_relation" label="Son" value="Son"></v-radio>
                <v-radio name="donor_relation" label="Wife" value="Wife"></v-radio>
                <v-radio name="donor_relation" label="Daughter" value="Daughter"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                hide-details 
                v-model="item.referral_name" 
                label="Referral Name*" 
                required
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                hide-details
                label="Referral Contact No.*"
                required
                v-model="item.referral_contact_number"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <p class="mb-0">Requesting Person*:</p>
              <v-radio-group row class="mt-0" readonly v-model="item.category_type_of_the_requesting_person">
                <v-radio name="Parents_name" label="Eye Bank Staff" value="Eye Bank Staff"></v-radio>
                <v-radio name="Parents_name" label="Hospital Staff" value="Hospital Staff"></v-radio>
                <v-radio class="mt-2" name="Parents_name" label="Volunteer" value="Volunteer"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-datetime-picker
                v-model="deathDate"
                time-format="HH:mm:ss"
                label="Date and Time of Death*"
              ></v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-datetime-picker
                v-model="consentDate"
                time-format="HH:mm:ss"
                label="Date and Time of Consent*"
              ></v-datetime-picker>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-datetime-picker
                v-model="excisionDate"
                time-format="HH:mm:ss"
                label="Date and Time of Excision*"
              ></v-datetime-picker>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                v-model="item.cause_of_death" 
                label="Cause of Death*" 
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field 
                v-model="item.temperature" 
                label="Temperature*" 
                required
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="item.consignment_information"
                label="Consignment Information*"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-datetime-picker
                v-model="receivedDate"
                time-format="HH:mm:ss"
                label="Received on*"
              ></v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
               <p class="body-1 mb-0 "><strong> INFORMATION ABOUT EYES RECEIVED</strong></p>
            </v-col>
            <v-col cols="12" md="5">
              <v-checkbox  class="mt-0" v-model="item.left_eye" label="L.E (OS)" value="LE" @click="restLE()"></v-checkbox>
              <v-textarea
                v-if="item.left_eye == 'LE'"
                v-model="item.condition_of_left_eye_ball_cornea_at_time_of_receive"
                label="Condition of Left Eye Ball/Cornea at time of receipt"
                rows="3"
                row-height="15"
              ></v-textarea>
              <v-textarea
                v-if="item.left_eye == 'LE'"
                v-model="item.condition_of_left_eye_ball_cornea_at_time_of_inspection"
                label="Condition of Left Eye Ball/Cornea on Inspection"
                rows="3"
                row-height="15"
              ></v-textarea>
            </v-col>
             <v-col cols="12" md="1"></v-col>
              <v-col cols="12" md="5">
              <v-checkbox class="mt-0" v-model="item.right_eye" label="R.E (OD)" value="RE" @click="restRE()"></v-checkbox>
              <v-textarea
                v-if="item.right_eye == 'RE'"
                v-model="item.condition_of_right_eye_ball_cornea_at_time_of_receive"
                label="Condition of Right Eye Ball/Cornea at time of receipt"
                rows="3"
                row-height="15"
              ></v-textarea>
              <v-textarea
                v-if="item.right_eye == 'RE'"
                v-model="item.condition_of_right_eye_ball_cornea_at_time_of_inspection"
                label="Condition of Right Eye Ball/Cornea on Inspection"
                rows="3"
                row-height="15"
              ></v-textarea>
            </v-col>
            <!-- <v-col cols="12" md="1"></v-col> -->
            <v-col cols="12" md="12">
              <v-textarea
                v-if="item.right_eye == 'RE' || item.left_eye == 'LE'"
                v-model="item.any_other_information"
                label="Any other Information"
                rows="2"
                row-height="15"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="5">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">Excision set</p>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker
                    v-model="excisionSterilizationDate"
                    time-format="HH:mm:ss"
                    label="Excision set date and time of sterilization*"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker
                    v-model="excisionExpiryDate"
                    time-format="HH:mm:ss"
                    label="Excision set date and time of expiry*"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="7">
                  <v-file-input
                    v-model="ExcisionSetInitals"
                    chips
                    accept="image/*"
                    label="Excision set initals*"
                    @change="onFileChangeExcision($event)"
                  />
                </v-col>
                <v-col v-if="!ExcisionSetInitalsObj&&item.excision_set_initials" cols="5">
                  <img  v-img style="width:100%" :src="!ExcisionSetInitalsObj?`https://api.stage.ebsr.in/${item.excision_set_initials}`:''" />
                </v-col>

                 <v-col v-if="!!ExcisionSetInitalsObj"  cols="5">
                 <img v-img style="width: 100%;" :src="ExcisionSetInitalsObj?ExcisionSetInitalsObj:''" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="1"></v-col>
            <v-col cols="12" md="5">
              <v-row>
                <v-col cols="12">
                  <p class="mb-0">Linen set</p>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker
                    v-model="linenSterilizationDate"
                    time-format="HH:mm:ss"
                    label="Linen set date and time of sterilization*"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="12">
                  <v-datetime-picker
                    v-model="linenExpiryDate"
                    time-format="HH:mm:ss"
                    label="Linen set date and time of expiry*"
                  ></v-datetime-picker>
                </v-col>
                <v-col cols="7" >
                  <v-file-input
                    v-model="LinenSetInitials"
                    chips
                    accept="image/*"
                    label="Linen set initials*"
                    @change="onFileChangeLinen"
                  />
                </v-col>
                 <v-col v-if="!LinenSetInitialsObj && item.linen_set_initials" cols="5">
                  <img  v-img style="width:100%" :src="!LinenSetInitialsObj?`https://api.stage.ebsr.in/${item.linen_set_initials}`:''" />
                </v-col>

                 <v-col v-if="!!LinenSetInitialsObj"  cols="5">
                 <img v-img  style="width: 100%;" :src="LinenSetInitialsObj?LinenSetInitialsObj:''" />
                </v-col>
                
              </v-row>
            </v-col>
            <v-col cols="12" md="1"></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-file-input
                v-model="file"
                chips
                accept="image/*"
                label="Permission Certificate*"
                @change="onFileChange"
              />
            </v-col>
            <v-col v-if="!fileObj&&item.permission_certificate" cols="2">
                  <img  v-img style="width:105%" :src="!fileObj?`https://api.stage.ebsr.in/${item.permission_certificate}`:''" />
                </v-col>

                 <v-col v-if="!!fileObj"  cols="2">
                 <img v-img style="width: 105%;" :src="fileObj?fileObj:''" />
                </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="samedata()"> Cancel </v-btn>
        <v-btn color="primary" @click="same(item)" :disabled="!formIsValid"> Update </v-btn>
      </v-card-actions>
      <v-snackbar v-model="snackbar" :timeout="timeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </v-dialog>
</template>
<script>
import donorService from '@/service/Donor.service'
import moment from 'moment'
const localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
// const token = localData.token
// const baseUrl = "https://api.stage.ebsr.in/"
// const userName = localData.user_name
// const userNumber = localData.user_number
const userId = localData.user_id


export default {
  data() {
    return{
      fileObj:'',
      LinenSetInitialsObj:'',
      ExcisionSetInitalsObj:'',
      snackbar: false,
      snackbarText: '',
      timeout: 2000,
      file:null,
      ExcisionSetInitals: null,
      LinenSetInitials: null
    }
  },

  props: ['visible', 'item','method'],

  computed: {
    formIsValid () {
      return (
        this.item.donor_name &&
        this.item.s_w_d_of &&
        this.item.donor_parent_guardian_name &&
        this.item.age &&
        this.item.sex &&
        this.item.postal_address &&
        this.item.phone_no &&
        this.item.donor_relative_name &&
        this.item.relation_with_donor &&
        this.item.referral_name &&
        this.item.referral_contact_number &&
        this.item.category_type_of_the_requesting_person &&
        this.item.date_of_time_of_death &&
        this.item.date_of_time_of_consent &&
        this.item.date_of_time_of_excision &&
        this.item.cause_of_death &&
        this.item.temperature &&
        // this.item.consignment_information &&
        this.item.received_on &&
        // this.item.right_eye &&
        // this.item.condition_of_right_eye_ball_cornea_at_time_of_receive &&
        // this.item.condition_of_right_eye_ball_cornea_at_time_of_inspection &&
        // this.item.left_eye &&
        // this.item.condition_of_left_eye_ball_cornea_at_time_of_receive &&
        // this.item.condition_of_left_eye_ball_cornea_at_time_of_inspection &&
        // this.item.any_other_information &&
        this.item.excision_set_date_and_time_of_sterilization &&
        this.item.excision_set_date_and_time_of_expiry &&
        this.item.linen_set_date_and_time_of_sterilization &&
        this.item.linen_set_date_and_time_of_expiry &&
        this.item.excision_set_initials &&
        this.item.linen_set_initials &&
        this.item.permission_certificate
      )
    },

    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      },
    },

    
    // url() {
    //   if (!this.LinenSetInitials) return '';
    //   return URL.createObjectURL(this.LinenSetInitials);
    // },

    // url1() {
    //   if (!this.ExcisionSetInitals) return '';
    //   return URL.createObjectURL(this.ExcisionSetInitals);
    // },
    // url2() {
    //   if (!this.file) return '';
    //   return URL.createObjectURL(this.file)
    // },
    


    
createdDate: {
      get() {
        return this.item.date?moment(this.item.date).format('YYYY-MM-DD HH:mm:ss'):''
      },
    },

    deathDate: {
      get() {
        return this.item.date_of_time_of_death?moment(this.item.date_of_time_of_death).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'date_of_time_of_death', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    consentDate: {
      get() {
        return this.item.date_of_time_of_consent?moment(this.item.date_of_time_of_consent).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'date_of_time_of_consent', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionDate: {
      get() {
        return this.item.date_of_time_of_excision?moment(this.item.date_of_time_of_excision).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'date_of_time_of_excision', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    receivedDate: {
      get() {
        return this.item.received_on?moment(this.item.received_on).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'received_on', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionSterilizationDate: {
      get() {
        return this.item.excision_set_date_and_time_of_sterilization?moment(this.item.excision_set_date_and_time_of_sterilization).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'excision_set_date_and_time_of_sterilization', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    excisionExpiryDate: {
      get() {
        return this.item.excision_set_date_and_time_of_expiry?moment(this.item.excision_set_date_and_time_of_expiry).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'excision_set_date_and_time_of_expiry', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    linenSterilizationDate: {
      get() {
        return this.item.linen_set_date_and_time_of_sterilization?moment(this.item.linen_set_date_and_time_of_sterilization).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'linen_set_date_and_time_of_sterilization', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

    linenExpiryDate: {
      get() {
        return this.item.linen_set_date_and_time_of_expiry?moment(this.item.linen_set_date_and_time_of_expiry).format('YYYY-MM-DD HH:mm:ss'):''
      },
      set(value) {
        this.$set(this.item, 'linen_set_date_and_time_of_expiry', moment(value).utcOffset("+05:30").format('YYYY-MM-DD HH:mm:ss'))
      },
    },

  },

  methods: {
    samedata(){
      this.show = false
      this.method()
    },
    same(item){
      this.editDonorinformation()
       this.$router.push({name:'Retrieval_Form', params: { donor_id: item.donor_id}});

    },
    restLE(){
      if(this.DonorInformationList.left_eye != 'LE'){
        this.item.condition_of_left_eye_ball_cornea_at_time_of_receive='',
        this.item.condition_of_left_eye_ball_cornea_at_time_of_inspection=''
      }
    },
    restRE(){
      if(this.DonorInformationList.right_eye != 'RE'){
        this.item.condition_of_right_eye_ball_cornea_at_time_of_receive='',
        this.item.condition_of_right_eye_ball_cornea_at_time_of_inspection=''
      }
    },

    onFileChange() {
      this.fileObj=URL.createObjectURL(this.file)
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      reader.onload = e => {
        this.item.permission_certificate = e.target.result
      }
    },
     onFileChangeExcision() {
      this.ExcisionSetInitalsObj=URL.createObjectURL(this.ExcisionSetInitals)
      const reader1 = new FileReader()
      reader1.readAsDataURL(this.ExcisionSetInitals)
      reader1.onload = e => {
        this.item.excision_set_initials = e.target.result
      }
    },
     onFileChangeLinen() {
      this.LinenSetInitialsObj=URL.createObjectURL(this.LinenSetInitials)
      const reader2 = new FileReader()
      reader2.readAsDataURL(this.LinenSetInitials)
      reader2.onload = e => {
        this.item.linen_set_initials = e.target.result
      }
    },

    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length > 9 ) {
          $event.preventDefault();
      }
    },

    onlyAge ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if (keyCode < 48 || keyCode > 57 || $event.target._value.length == 2 ) {
          $event.preventDefault();
      }
    },

    async editDonorinformation() {
      if(this.item.phone_no.length  < 10){
        return false,
        this.snackbarText = "Phone number should be 10 character.",
        this.snackbar = true
      }
      try {
        const data = {
          donor_name: this.item.donor_name,
          s_w_d_of: this.item.s_w_d_of,
          donor_parent_guardian_name: this.item.donor_parent_guardian_name,
          age: this.item.age,
          sex: this.item.sex,
          postal_address: this.item.postal_address,
          phone_no: this.item.phone_no,
          donor_relative_name: this.item.donor_relative_name,
          relation_with_donor: this.item.relation_with_donor,
          referral_name: this.item.referral_name,
          referral_contact_number: this.item.referral_contact_number,
          category_type_of_the_requesting_person: this.item.category_type_of_the_requesting_person,
          date_of_time_of_death: this.item.date_of_time_of_death,
          date_of_time_of_consent: this.item.date_of_time_of_consent,
          date_of_time_of_excision: this.item.date_of_time_of_excision,
          cause_of_death: this.item.cause_of_death,
          temperature: this.item.temperature,
          consignment_information: this.item.consignment_information,
          received_on: this.item.received_on,
          right_eye: this.item.right_eye,
          condition_of_right_eye_ball_cornea_at_time_of_receive: this.item.condition_of_right_eye_ball_cornea_at_time_of_receive,
          condition_of_right_eye_ball_cornea_at_time_of_inspection: this.item.condition_of_right_eye_ball_cornea_at_time_of_inspection,
          left_eye: this.item.left_eye,
          condition_of_left_eye_ball_cornea_at_time_of_receive: this.item.condition_of_left_eye_ball_cornea_at_time_of_receive,
          condition_of_left_eye_ball_cornea_at_time_of_inspection: this.item.condition_of_left_eye_ball_cornea_at_time_of_inspection,
          any_other_information: this.item.any_other_information,
          excision_set_date_and_time_of_sterilization: this.item.excision_set_date_and_time_of_sterilization,
          excision_set_date_and_time_of_expiry: this.item.excision_set_date_and_time_of_expiry,
          excision_set_initials: this.item.excision_set_initials,
          linen_set_date_and_time_of_sterilization: this.item.linen_set_date_and_time_of_sterilization,
          linen_set_date_and_time_of_expiry: this.item.linen_set_date_and_time_of_expiry,
          linen_set_initials: this.item.linen_set_initials,
          user_id: userId,
          donor_id: this.item.donor_id,
          permission_certificate: this.item.permission_certificate
        }
        const service = new donorService()
        const response = await service.updateDonorInformation(data)
        if(response.status == 200){
          this.snackbarText = response.message,
          this.snackbar = true
          setTimeout(() => {
            this.show = false
          }, 1000)
        }else{
          this.snackbarText = response.message,
          this.snackbar = true
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
